<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'successMessage',
    props: {
        success_message: String,
        active: Boolean,
    },
    data() {
      return{

      }  
    },
})
</script>

<template>
<Teleport to="body">
<Transition name="slide-fade">
    <div id="wrapper" v-if="active">
        <p><i class='bx bxs-message-check bx-sm'></i>{{success_message}}</p>
    </div>
</Transition>
</Teleport>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}


#wrapper{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #00ca4e;
  font-family: Nunito;
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
}
i{
  margin-right: 0.3em;
  vertical-align: middle;
}
</style>