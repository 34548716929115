<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Header',
    data(){
      return{

      }
    },
})
</script>

<template>
    <div id="wrapper">
        <h1>nom projet ici</h1>
    </div>
</template>

<style scoped>
#wrapper{
    width: 100%;
    height: 10em;
    background: #EEEEEE;
    text-align: center;
}
</style>