<script>
import { defineComponent } from 'vue'
import { calcWeek, calcYear } from './calcDate'
import ErrMsg from '@/components/ErrMsg.vue'
import SuccessMsg from '@/components/SuccessMsg.vue'

const axios = require('axios')

const auth = {
    headers : {
        authorization: `Bearer ${localStorage.getItem('token')}`
    }
}

export default defineComponent({
    name: 'FolDash',
    components: {
        ErrMsg,
        SuccessMsg,
    },
    props: {
        cityId: String,
        cityName: String,

        // collectivites
        nbOffresLogementsColl: Number,
        nbPlacesColl: Number,
        nbLogementsPrisColl: Number,
        nbPersonnesInstalleesColl: Number,
        nbreDePersEnCoursDeRelogementColl: Number,
        nbRefusFamillesColl: Number,
        nbRefusPersonneColl: Number,

        // entreprises-asso
        nbOffresDeLogementsEntr: Number,
        nbPlacesEntr: Number,
        nbreDePersEnCoursDeRelogementEntr: Number,
        nbRefusFamillesEntr: Number,
        nbRefusPersonneEntr: Number,

        // bailleurs
        nbOffreDeLogementsBail: Number,
        nbDePlacesBail: Number,
        nbLogementPrisBail: Number,
        nbPersonnesInstalleesBail: Number,
        nbreDePersEnCoursDeRelogementBail: Number,
        nbRefusFamillesBail: Number,
        nbRefusPersonneBail: Number,

        // CHRS
        nbUkrainiensInstallesChrs: Number,
        dontXEnCoursDeRelogementChrs: Number,
        dontXRefusApsChrs: Number,
        nbRefusFamillesChrs: Number,
        nbRefusPersonneChrs: Number,

        // particuliers
        nbOffresParticuliersPart: Number,
        nbDeLogementsIndependantsParticuliersPart: Number,
        nbPlacesLogementsIndependantsParticuliersPart: Number,
        nbUkrainiensInstallesChezDesParticuliersAvecImlPart: Number,
        nbUkrainiensDemandantAEtreRelogesPart: Number,
        visiteParLaFolPart: Number,
        nbRefusFamillesPart: Number,
        nbRefusPersonnePart: Number,
    },
    data(){
      return{
        nowDate: '',
        nowWeek: calcWeek(),
        nowYear: calcYear(),
        active_err: false,
        active_succ: false,
        erro_msg: '',
        succ_msg: '', 
        showObs: false,
        old_observations: [],
        emptyOld: false,
        observations: [],
        new_observation: '',
        _nbOffresLogementsColl: this.nbOffresLogementsColl,
        _nbPlacesColl: this.nbPlacesColl,
        _nbLogementsPrisColl: this.nbLogementsPrisColl,
        _nbPersonnesInstalleesColl: this.nbPersonnesInstalleesColl,
        _nbreDePersEnCoursDeRelogementColl: this.nbreDePersEnCoursDeRelogementColl,
        _nbRefusFamillesColl: this.nbRefusFamillesColl,
        _nbRefusPersonneColl: this.nbRefusPersonneColl,
        _nbOffresDeLogementsEntr: this.nbOffresDeLogementsEntr,
        _nbPlacesEntr: this.nbPlacesEntr,
        _nbreDePersEnCoursDeRelogementEntr: this.nbreDePersEnCoursDeRelogementEntr,
        _nbRefusFamillesEntr: this.nbRefusFamillesEntr,
        _nbRefusPersonneEntr: this.nbRefusPersonneEntr,
        _nbOffreDeLogementsBail: this.nbOffreDeLogementsBail,
        _nbDePlacesBail: this.nbDePlacesBail,
        _nbLogementPrisBail: this.nbLogementPrisBail,
        _nbPersonnesInstalleesBail: this.nbPersonnesInstalleesBail,
        _nbreDePersEnCoursDeRelogementBail: this.nbreDePersEnCoursDeRelogementBail,
        _nbRefusFamillesBail: this.nbRefusFamillesBail,
        _nbRefusPersonneBail: this.nbRefusPersonneBail,
        _nbUkrainiensInstallesChrs: this.nbUkrainiensInstallesChrs,
        _dontXEnCoursDeRelogementChrs: this.dontXEnCoursDeRelogementChrs,
        _dontXRefusApsChrs: this.dontXRefusApsChrs,
        _nbRefusFamillesChrs: this.nbRefusFamillesChrs,
        _nbRefusPersonneChrs: this.nbRefusPersonneChrs,
        _nbOffresParticuliersPart: this.nbOffresParticuliersPart,
        _nbDeLogementsIndependantsParticuliersPart: this.nbDeLogementsIndependantsParticuliersPart,
        _nbPlacesLogementsIndependantsParticuliersPart: this.nbPlacesLogementsIndependantsParticuliersPart,
        _nbUkrainiensInstallesChezDesParticuliersAvecImlPart: this.nbUkrainiensInstallesChezDesParticuliersAvecImlPart,
        _nbUkrainiensDemandantAEtreRelogesPart: this.nbUkrainiensDemandantAEtreRelogesPart,
        _visiteParLaFolPart: this.visiteParLaFolPart,
        _nbRefusFamillesPart: this.nbRefusFamillesPart,
        _nbRefusPersonnePart: this.nbRefusPersonnePart,
      }
    },
    methods: {
        errorReset(){
            if(this.active_err){
                setTimeout(() => {
                    this.active_err = !this.active_err
                }, 3000)
            }
        },
        successReset(){
            if(this.active_succ){
                setTimeout(() => {
                    this.active_succ = !this.active_succ
                }, 3000)
            }
        },
        checkFull(){
            if(this.cityId === '' || this.cityName === '') return true
        },
        addObservation(){
            if(this.new_observation === '') return
            this.observations.push(`${this.new_observation.toString()} - (${this.nowDate})`)
        },
        removeObservation(element){
            const index = this.observations.indexOf(element)
            this.observations.splice(index, 1)
        },
        async update(){
            if(confirm('Confirmer les modifications?')){
                const chrs_data = {
                    Nb_Ukrainiens_installés_chrs: this._nbUkrainiensInstallesChrs || this.nbUkrainiensInstallesChrs,
                    Dont_x_en_cours_de_relogemen_chrs: this._dontXEnCoursDeRelogementChrs || this.dontXEnCoursDeRelogementChrs,
                    Dont_x_refus_APS_chrs: this._dontXRefusApsChrs || this.dontXRefusApsChrs,
                    Nb_refus_famille_chrs: this._nbRefusFamillesChrs || this.nbRefusFamillesChrs,
                    Nb_refus_personne_chrs: this._nbRefusPersonneChrs || this.nbRefusPersonneChrs,
                    ville_id: this.cityId,
                }

                const part_data = {
                    Nb_offres_particuliers: this._nbOffresParticuliersPart || this.nbOffresParticuliersPart,
                    Nb_de_logements_indépendants_particuliers: this._nbDeLogementsIndependantsParticuliersPart || this.nbDeLogementsIndependantsParticuliersPart,
                    Nb_places_logements_indépendants_particuliers: this._nbPlacesLogementsIndependantsParticuliersPart || this.nbPlacesLogementsIndependantsParticuliersPart,
                    Nb_Ukrainiens_installés_chez_des_particuliers_avec_iml: this._nbUkrainiensInstallesChezDesParticuliersAvecImlPart || this.nbUkrainiensInstallesChezDesParticuliersAvecImlPart,
                    Nb_Ukrainiens_demandant_à_être_relogés_particuliers: this._nbUkrainiensDemandantAEtreRelogesPart || this.nbUkrainiensDemandantAEtreRelogesPart,
                    Visité_par_la_fol_particuliers: this._visiteParLaFolPart || this.visiteParLaFolPart,
                    Nb_refus_famille_particuliers: this._nbRefusFamillesPart || this.nbRefusFamillesPart,
                    Nb_refus_personne_particuliers: this._nbRefusPersonnePart || this.nbRefusPersonnePart,
                    ville_id: this.cityId,
                }

                const bail_data = {
                    Nb_offres_de_logements_bailleurs: this._nbOffreDeLogementsBail || this.nbOffreDeLogementsBail,
                    Nb_places_bailleurs: this._nbDePlacesBail || this.nbDePlacesBail,
                    Nb_logements_pris_bailleurs: this._nbLogementPrisBail || this.nbLogementPrisBail,
                    Nb_personnes_installées_bailleurs: this._nbPersonnesInstalleesBail || this.nbPersonnesInstalleesBail,
                    Nbre_de_pers_en_cours_de_relogemen_bailleurs: this._nbreDePersEnCoursDeRelogementBail || this.nbreDePersEnCoursDeRelogementBail,
                    Nb_refus_famille_bailleurs: this._nbRefusFamillesBail || this.nbRefusFamillesBail,
                    Nb_refus_personne_bailleurs: this._nbRefusPersonneBail || this.nbRefusPersonneBail,
                    ville_id: this.cityId,
                }

                const coll_data = {
                    Nb_offres_de_logements_coll: this._nbOffresLogementsColl || this.nbOffresLogementsColl,
                    Nb_places_coll: this._nbPlacesColl || this.nbPlacesColl,
                    Nb_logements_pris_coll: this._nbLogementsPrisColl || this.nbLogementsPrisColl,
                    Nb_personnes_installées_coll: this._nbPersonnesInstalleesColl || this.nbPersonnesInstalleesColl,
                    Nbre_de_pers_en_cours_de_relogemen_coll: this._nbreDePersEnCoursDeRelogementColl || this.nbreDePersEnCoursDeRelogementColl,
                    Nb_refus_famille_coll: this._nbRefusFamillesColl || this.nbRefusFamillesColl,
                    Nb_refus_personne_coll: this._nbRefusPersonneColl || this.nbRefusPersonneColl,
                    ville_id: this.cityId,
                }

                const entr_data = {
                    Nb_offres_de_logements_entr: this._nbOffresDeLogementsEntr || this.nbOffresDeLogementsEntr,
                    Nb_places_entr: this._nbPlacesEntr || this.nbPlacesEntr,
                    Nbre_de_pers_en_cours_de_relogemen_entr: this._nbreDePersEnCoursDeRelogementEntr || this.nbreDePersEnCoursDeRelogementEntr,
                    Nb_refus_famille_entr: this._nbRefusFamillesEntr || this.nbRefusFamillesEntr,
                    Nb_refus_personne_entr: this._nbRefusPersonneEntr || this.nbRefusPersonneEntr,
                    ville_id: this.cityId,
                }

                await axios.post('api/add-chrs', chrs_data, auth).then(async (res) => {
                    if(res.status !== 200){
                        this.erro_msg = `Error add-chrs`
                        this.active_err = true
                        return this.errorReset()
                    }

                    await axios.post('/api/add-particuliers', part_data, auth).then(async (res) => {
                        if(res.status !== 200){
                            this.erro_msg = `Error add-part`
                            this.active_err = true
                            return this.errorReset()
                        }

                        await axios.post('/api/add-bailleurs', bail_data, auth).then(async (res) => {
                            if(res.status !== 200){
                                this.erro_msg = `Error add-bail`
                                this.active_err = true
                                return this.errorReset()
                            }

                            await axios.post('/api/add-collectivites', coll_data, auth).then(async (res) => {
                                if(res.status !== 200){
                                    this.erro_msg = `Error add-coll`
                                    this.active_err = true
                                    return this.errorReset()
                                }

                                await axios.post('/api/add-entreprises', entr_data, auth).then(async (res) => {
                                    if(res.status !== 200){
                                        this.erro_msg = `Error add-entr`
                                        this.active_err = true
                                        return this.errorReset()
                                    }
                                    
                                    const obs_data = {
                                        observation: this.observations,
                                        ville_id: this.cityId,
                                    }

                                    await axios.post('/api/add-observations', obs_data, auth).then(async (res) => {
                                        if(res.status !== 200){
                                            this.erro_msg = `Error add-obs`
                                            this.active_err = true
                                            return this.errorReset()
                                        }

                                        this._nbOffresLogementsColl = ''
                                        this._nbPlacesColl = ''
                                        this._nbLogementsPrisColl = ''
                                        this._nbPersonnesInstalleesColl = ''
                                        this._nbreDePersEnCoursDeRelogementColl = ''
                                        this._nbRefusFamillesColl = ''
                                        this._nbRefusPersonneColl = ''
                                        this._nbOffresDeLogementsEntr = ''
                                        this._nbPlacesEntr = ''
                                        this._nbreDePersEnCoursDeRelogementEntr = ''
                                        this._nbRefusFamillesEntr = ''
                                        this._nbRefusPersonneEntr = ''
                                        this._nbOffreDeLogementsBail = ''
                                        this._nbDePlacesBail = ''
                                        this._nbLogementPrisBail = ''
                                        this._nbPersonnesInstalleesBail = '' 
                                        this._nbreDePersEnCoursDeRelogementBail = '' 
                                        this._nbRefusFamillesBail = ''
                                        this._nbRefusPersonneBail = '' 
                                        this._nbUkrainiensInstallesChrs = '' 
                                        this._dontXEnCoursDeRelogementChrs = '' 
                                        this._dontXRefusApsChrs = '' 
                                        this._nbRefusFamillesChrs = '' 
                                        this._nbRefusPersonneChrs = '' 
                                        this._nbOffresParticuliersPart = '' 
                                        this._nbDeLogementsIndependantsParticuliersPart = '' 
                                        this._nbPlacesLogementsIndependantsParticuliersPart = ''
                                        this._nbUkrainiensInstallesChezDesParticuliersAvecImlPart = ''
                                        this._nbUkrainiensDemandantAEtreRelogesPart = '' 
                                        this._visiteParLaFolPart = '' 
                                        this._nbRefusFamillesPart = '' 
                                        this._nbRefusPersonnePart = '' 

                                        this.succ_msg = 'Les données sont mises à jour, Redirection...'
                                        this.active_succ = true
                                        this.successReset()
                                        // setTimeout(() => {
                                        //     location.reload()
                                        // }, 1000)
                                    }).catch((err) => {
                                        console.log(err)
                                        return
                                    })
                                }).catch((err) => {
                                    console.log(err)
                                    return
                                })
                            }).catch((err) => {
                                console.log(err)
                                return
                            })
                        }).catch((err) => {
                            console.log(err)
                            return
                        })
                    }).catch((err) => {
                        console.log(err)
                        return
                    })
                }).catch((err) => {
                    console.log(err)
                    return
                })

            }
        },
        removeAllValues(){
            this._nbOffresLogementsColl = ''
            this._nbPlacesColl = ''
            this._nbLogementsPrisColl = ''
            this._nbPersonnesInstalleesColl = ''
            this._nbreDePersEnCoursDeRelogementColl = ''
            this._nbRefusFamillesColl = ''
            this._nbRefusPersonneColl = ''
            this._nbOffresDeLogementsEntr = ''
            this._nbPlacesEntr = ''
            this._nbreDePersEnCoursDeRelogementEntr = ''
            this._nbRefusFamillesEntr = ''
            this._nbRefusPersonneEntr = ''
            this._nbOffreDeLogementsBail = ''
            this._nbDePlacesBail = ''
            this._nbLogementPrisBail = ''
            this._nbPersonnesInstalleesBail = '' 
            this._nbreDePersEnCoursDeRelogementBail = '' 
            this._nbRefusFamillesBail = ''
            this._nbRefusPersonneBail = '' 
            this._nbUkrainiensInstallesChrs = '' 
            this._dontXEnCoursDeRelogementChrs = '' 
            this._dontXRefusApsChrs = '' 
            this._nbRefusFamillesChrs = '' 
            this._nbRefusPersonneChrs = '' 
            this._nbOffresParticuliersPart = '' 
            this._nbDeLogementsIndependantsParticuliersPart = '' 
            this._nbPlacesLogementsIndependantsParticuliersPart = ''
            this._nbUkrainiensInstallesChezDesParticuliersAvecImlPart = ''
            this._nbUkrainiensDemandantAEtreRelogesPart = '' 
            this._visiteParLaFolPart = '' 
            this._nbRefusFamillesPart = '' 
            this._nbRefusPersonnePart = '' 
        },  
        async validate(){

            await this.update()

            const data = {
                ville_id: this.cityId
            }

            await axios.post('/api/validate', data, auth).then((res) => {
                if(res.status !== 200){
                    this.erro_msg = `Erreur validation`
                    this.active_err = true
                    return this.errorReset()
                }
                this.succ_msg = 'Les données ont été validées, Redirection...'
                this.active_succ = true
                this.successReset()
                return setTimeout(() => {
                location.reload()
                }, 1000)
            }).catch((err) => {
                console.log(err);
                this.erro_msg = `Erreur validation`
                this.active_err = true
                return this.errorReset()
            })
        },
        getOldObservations(){
            const old_data = {
                ville_id: this.cityId
            }

            axios.post('/api/old-obs', old_data, auth).then((res) => {
                if(res.data.empty){
                    return this.emptyOld = true
                }
                else{
                    this.emptyOld = false
                    res.data.forEach((element) => {
                        this.old_observations.push(element)
                    })
                }
                
            }).catch((err) => {
                console.log(err)
                return
            })

            this.showObs = true
        },
        closeOldObservations(){
            this.showObs = false
            this.old_observations = []
        }
    },
    created(){
        const date = new Date()
        const format = date.toLocaleString('fr-FR', {timeZone: 'Europe/Paris'}).split(' ')[0]

        this.nowDate = format.replace(/\./g, '/')
    }
})
</script>

<template>
    <div id="fol-wrapper">
        <ErrMsg :active="active_err" :error_message="erro_msg"/>
        <SuccessMsg :active="active_succ" :success_message="succ_msg"/>
        <div v-if="checkFull()" id="defaultDiv">
            <h1>Selectionez une ville</h1>
            <p>Pour mettre à jour, visualizer ou valider des données</p>
        </div>

        <div v-if="!checkFull()" id="table-edit-Div">
            <h1>{{cityName}}</h1>
            <p style="font-weight: bold;">{{nowDate}} semaine {{nowWeek}}-{{nowYear}}</p>

            <p style="color: #ff8000;">Données déjà existantes et enregistrées</p>



            <div id="full-cat-wrapper">
                <div id="collectivites">
                <h2>Logements collectivités</h2>

                <div class="input-wrapper" ref="fullWrapper">
                    <label for="nb-offres-logements-coll">Nb offres de logements</label>
                    <input class="cat-inputs" type="number" name="nb-offres-logements-coll" id="" :placeholder="nbOffresLogementsColl" v-model="_nbOffresLogementsColl">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-places-coll">Nb places</label>
                    <input class="cat-inputs" type="number" name="nb-places-coll" id="" :placeholder="nbPlacesColl" v-model="_nbPlacesColl">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-logements-pris-coll">Nb logements pris</label>
                    <input class="cat-inputs" type="number" name="nb-logements-pris-coll" id="" :placeholder="nbLogementsPrisColl" v-model="_nbLogementsPrisColl">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-personnes-installees-coll">Nb personnes installées</label>
                    <input class="cat-inputs" type="number" name="nb-personnes-installees-coll" id="" :placeholder="nbPersonnesInstalleesColl" v-model="_nbPersonnesInstalleesColl">
                </div>
                

                <div class="input-wrapper">
                    <label for="nbre-de-pers-en-cours-de-relogement-coll">Nbre de pers en cours de relogement</label>
                    <input class="cat-inputs" type="number" name="nbre-de-pers-en-cours-de-relogement-coll" id="" :placeholder="nbreDePersEnCoursDeRelogementColl" v-model="_nbreDePersEnCoursDeRelogementColl">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-refus-familles-coll">Nb refus familles</label>
                    <input class="cat-inputs" type="number" name="nb-refus-familles-coll" id="" :placeholder="nbRefusFamillesColl" v-model="_nbRefusFamillesColl">
                </div>
                


                <div class="input-wrapper">
                    <label for="nb-refus-personne-coll">Nb refus personne</label>
                    <input class="cat-inputs" type="number" name="nb-refus-personne-coll" id="" :placeholder="nbRefusPersonneColl" v-model="_nbRefusPersonneColl">
                </div>
                
            </div>

            <div class="associations">
                <h2>Logements entreprises-Association</h2>

                <div class="input-wrapper">
                    <label for="nb-offres-de-logements-entr">Nb offres de logements</label>
                    <input class="cat-inputs" type="number" name="nb-offres-de-logements-entr" id="" :placeholder="nbOffresDeLogementsEntr" v-model="_nbOffresDeLogementsEntr">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-places-entr">Nb places</label>
                    <input class="cat-inputs" type="number" name="nb-places-entr" id="" :placeholder="nbPlacesEntr" v-model="_nbPlacesEntr">
                </div>
                

                <div class="input-wrapper">
                    <label for="nbre-de-pers-en-cours-de-relogement-entr">Nbre de pers en cours de relogement</label>
                    <input class="cat-inputs" type="number" name="nbre-de-pers-en-cours-de-relogement-entr" id="" :placeholder="nbreDePersEnCoursDeRelogementEntr" v-model="_nbreDePersEnCoursDeRelogementEntr">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-refus-familles-entr">Nb refus familles</label>
                    <input class="cat-inputs" type="number" name="nb-refus-familles-entr" id="" :placeholder="nbRefusFamillesEntr" v-model="_nbRefusFamillesEntr">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-refus-personne-entr">Nb refus personne</label>
                    <input class="cat-inputs" type="number" name="nb-refus-personne-entr" id="" :placeholder="nbRefusPersonneEntr" v-model="_nbRefusPersonneEntr">
                </div>
                
            </div>

            <div class="bailleurs">
                <h2>Logements bailleurs</h2>

                <div class="input-wrapper">
                    <label for="nb-offre-de-logements-baill">Nb offre de logements</label>
                    <input class="cat-inputs" type="number" name="nb-offre-de-logements-bail" id="" :placeholder="nbOffreDeLogementsBail" v-model="_nbOffreDeLogementsBail">
                </div>
                


                <div class="input-wrapper">
                    <label for="nb-de-places-bail">Nb de places</label>
                    <input class="cat-inputs" type="number" name="nb-de-places-bail" id="" :placeholder="nbDePlacesBail" v-model="_nbDePlacesBail">
                </div>
                


                <div class="input-wrapper">
                    <label for="nb-logement-pris-bail">Nb logement pris</label>
                    <input class="cat-inputs" type="number" name="nb-logement-pris-bail" id="" :placeholder="nbLogementPrisBail" v-model="_nbLogementPrisBail">
                </div>
                


                <div class="input-wrapper">
                    <label for="nb-personnes-installees-bail">Nb personnes installées</label>
                    <input class="cat-inputs" type="number" name="nb-personnes-installees-bail" id="" :placeholder="nbPersonnesInstalleesBail" v-model="_nbPersonnesInstalleesBail">
                </div>
                


                <div class="input-wrapper">
                    <label for="nbre-de-pers-en-cours-de-relogement-bail">Nbre de pers en cours de relogement</label>
                    <input class="cat-inputs" type="number" name="nbre-de-pers-en-cours-de-relogement-bail" id="" :placeholder="nbreDePersEnCoursDeRelogementBail" v-model="_nbreDePersEnCoursDeRelogementBail">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-refus-familles-bail">Nb refus familles</label>
                    <input class="cat-inputs" type="number" name="nb-refus-familles-bail" id="" :placeholder="nbRefusFamillesBail" v-model="_nbRefusFamillesBail">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-refus-personne-bail">Nb refus personne</label>
                    <input class="cat-inputs" type="number" name="nb-refus-personne-bail" id="" :placeholder="nbRefusPersonneBail" v-model="_nbRefusPersonneBail">
                </div>
                
            </div>

             <div class="chrs">
                <h2>Hébergements CHRS</h2>

                <div class="input-wrapper">
                    <label for="nb-ukrainiens-installes-chrs">Nb Ukrainiens installés</label>
                    <input class="cat-inputs" type="number" name="nb-ukrainiens-installes-chrs" id="" :placeholder="nbUkrainiensInstallesChrs" v-model="_nbUkrainiensInstallesChrs">
                </div>
                
                <div class="input-wrapper">
                    <label for="dont-x-en-cours-de-relogement-chrs">Dont x en cours de relogement</label>
                    <input class="cat-inputs" type="number" name="dont-x-en-cours-de-relogement-chrs" id="" :placeholder="dontXEnCoursDeRelogementChrs" v-model="_dontXEnCoursDeRelogementChrs">
                </div>

                <div class="input-wrapper">
                    <label for="dont-x-refus-aps-chrs">Dont x refus APS</label>
                    <input class="cat-inputs" type="number" name="dont-x-refus-aps-chrs" id="" :placeholder="dontXRefusApsChrs" v-model="_dontXRefusApsChrs">
                </div>

                <div class="input-wrapper">
                    <label for="nb-refus-familles-chrs">Nb refus familles</label>
                    <input class="cat-inputs" type="number" name="nb-refus-familles-chrs" id="" :placeholder="nbRefusFamillesChrs" v-model="_nbRefusFamillesChrs">
                </div>
        
                <div class="input-wrapper">
                    <label for="nb-refus-personne-chrs">Nb refus personne</label>
                    <input class="cat-inputs" type="number" name="nb-refus-personne-chrs" id="" :placeholder="nbRefusPersonneChrs" v-model="_nbRefusPersonneChrs">
                </div> 
            </div>

            <div class="particuliers">
                <h2>Hébergements particuliers</h2>

                <div class="input-wrapper">
                    <label for="nb-offres-particuliers-part">Nb offres particuliers</label>
                    <input class="cat-inputs" type="number" name="nb-offres-particuliers-part" id="" :placeholder="nbOffresParticuliersPart" v-model="_nbOffresParticuliersPart">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-de-logements-independants-particuliers-part">Nb de logements indépendants particuliers</label>
                    <input class="cat-inputs" type="number" name="nb-de-logements-independants-particuliers-part" id="" :placeholder="nbDeLogementsIndependantsParticuliersPart" v-model="_nbDeLogementsIndependantsParticuliersPart">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-places-logements-independants-particuliers-part">Nb places logements indépendants particuliers</label>
                    <input class="cat-inputs" type="number" name="nb-places-logements-independants-particuliers-part" id="" :placeholder="nbPlacesLogementsIndependantsParticuliersPart" v-model="_nbPlacesLogementsIndependantsParticuliersPart">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-ukrainiens-installes-chez-des-particuliers-avec-iml-part">Nb Ukrainiens installés chez des particuliers avec IML</label>
                    <input class="cat-inputs" type="number" name="nb-ukrainiens-installes-chez-des-particuliers-avec-iml-part" id="" :placeholder="nbUkrainiensInstallesChezDesParticuliersAvecImlPart" v-model="_nbUkrainiensInstallesChezDesParticuliersAvecImlPart">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-ukrainiens-demandant-a-etre-reloges-part">Nb Ukrainiens demandant à être relogés</label>
                    <input class="cat-inputs" type="number" name="nb-ukrainiens-demandant-a-etre-reloges-part" id="" :placeholder="nbUkrainiensDemandantAEtreRelogesPart" v-model="_nbUkrainiensDemandantAEtreRelogesPart">
                </div>
                

                <div class="input-wrapper">
                    <label for="visite-par-la-fol-part">Visité par la FOL</label>
                    <input class="cat-inputs" type="number" name="visite-par-la-fol-part" id="" :placeholder="visiteParLaFolPart" v-model="_visiteParLaFolPart">
                </div>
                

                <div class="input-wrapper">
                    <label for="nb-refus-familles-part">Nb refus familles</label>
                <input class="cat-inputs" type="number" name="nb-refus-familles-part" id="" :placeholder="nbRefusFamillesPart" v-model="_nbRefusFamillesPart">
                </div>
                


                <div class="input-wrapper">
                    <label for="nb-refus-personne-part">Nb refus personne</label>
                    <input class="cat-inputs" type="number" name="nb-refus-personne-part" id="" :placeholder="nbRefusPersonnePart" v-model="_nbRefusPersonnePart">
                </div>
                
            </div>

            <div class="observations">
                
                <textarea name="" id="" cols="30" rows="3" v-model="new_observation" placeholder="Ecrire une observations ici"></textarea>
                <div>
                    <button @click="addObservation" class="obs-btns">Ajouter l'observation<i class='bx bx-plus bx-sm'></i></button>
                    <button @click="getOldObservations" class="obs-btns">Voir observations<i class='bx bx-show-alt bx-sm'></i></button>
                </div>


                <div v-for="i in observations" :key="i" class="obs-p-overflow">
                    <p class="obs-p">{{i}}</p>
                    <button @click="removeObservation(i)"><i class='bx bx-x'></i></button>
                </div>
                <Teleport to="div">
                    <Transition>
                        <div id="modal" v-if="showObs">
                            <button id="close-modal-btn" @click="closeOldObservations"><i class='bx bx-x'></i></button>
                            <p v-if="emptyOld">Aucune observation à voir</p>
                            <li v-for="i in old_observations" :key="i">{{i}}</li>
                        </div>
                    </Transition>
                </Teleport>
            </div>
            </div>

            <div id="btn-wrapper">
                <!-- <button @click="update" class="update-btn end-btn">Mettre à jour<i class='bx bx-refresh bx-sm'></i></button> -->
                <button @click="validate" class="validate-btn end-btn">Mettre à jour et Valider<i class='bx bx-check bx-sm'></i></button>
                <button @click="removeAllValues" class="clear-btn end-btn">Nettoyer les champs<i class='bx bxs-trash bx-sm'></i></button>
            </div>
        </div>
    </div>
</template>

<style scoped>
#modal{
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #eeeeee;
    color: black;
    width: 30em;
    font-weight: bold;
    padding: 2em;
    text-align: center;
}
#modal li{
    margin-top: 1em;
}
#modal button{
    color: #FF605C;
    border: none;
    font-size: 2em;
}
#modal button:hover{
    color: #1b2631;
}

#fol-wrapper{
    color: #1b2631;
    border-radius: 10px;
    background: #0B6BA8;
    padding: 1em;
    margin: 1em;
}
#defaultDiv{
    font-weight: bold;
    margin: auto;
    color: #ffffff;
}
#defaultDiv h1, p{
    margin: 0;
}
#full-cat-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1em;
}
input{
    font-size: 1.2em;
    outline: none;
    text-align: center;
}
label{
    color: #ffffff;
}
.cat-inputs{
    width: 2.5em;
    color: black;
}
input::placeholder {
    color: #ff8000;
    font-weight: bold;
}
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type=number] {
  -moz-appearance: textfield;
}
textarea{
    outline: none;
    font-size: 1.2em;
    width: 100%;
    resize: none;
}

.input-wrapper{
    display: flex;
    justify-content: end;
    border-top: solid 1px black;
}
.input-wrapper label{
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    font-weight: bold;
    font-size: 0.9em;
}
#btn-wrapper{
    margin-top: 2em;
    margin-bottom: 1em;
    margin-inline: auto;
    display: flex;
    flex-direction: row;
}
.observations{
    display: flex;
    flex-direction: column;
    margin-block: auto;
}
#obs-inner{
    height: 15em;
    overflow: auto;
}
.obs-p-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.obs-btns{
    margin-inline: auto;
    outline: none;
    border: none;
    border-radius: 15px;
    margin: 0.5em;
}
.obs-btns:hover{
    color: #ffffff;
    background: #1b2631;
}
.obs-p{
    color: #ffffff;
}

.particuliers{
    display: flex;
    flex-direction: column;
}
.end-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    text-align: center;
    height: 3em;
    color: white;
    border-radius: 25px;
    font-weight: bold;
    margin-inline: 0.5em;
    padding: 0.5em;

}
.update-btn{
    background: #FFBD44;
}
.validate-btn{
    background: #00CA4E;
}
.clear-btn{
    background: #FF605C;
}
i{
    vertical-align: middle;
}
</style>