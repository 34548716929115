<script>
import { defineComponent } from 'vue'
import ErrMsg from '@/components/ErrMsg.vue'
import SuccessMsg from '@/components/SuccessMsg.vue'
import { emailValidator } from '@/components/emailValidator.ts'
const axios = require('axios')


const auth = {
    headers : {
        authorization: `Bearer ${localStorage.getItem('token')}`
    }
}

export default defineComponent({
    name: 'AdminDashboard',
    components: {
        ErrMsg,
        SuccessMsg,
    },
    data(){
      return{
        users: [],
        api_url: null,
        show_add: false,
        role: '',
        password: Math.random().toString(36).slice(2),
        email: '',
        numero: '',
        nom: '',
        prenom: '',
        modify: false,
        modify_id: '',
        modify_email: '',
        modify_numero: '',
        modify_nom: '',
        modify_prenom: '',
        password_changed: false,
        new_pwd: '',
        active_err: false,
        active_succ: false,
        erro_msg: '',
        succ_msg: '', 
      }
    },
    methods: {
        errorReset(){
            if(this.active_err){
                setTimeout(() => {
                    this.active_err = !this.active_err
                }, 3000)
            }
        },
        successReset(){
            if(this.active_succ){
                setTimeout(() => {
                    this.active_succ = !this.active_succ
                }, 3000)
            }
        },
        logout(){
            localStorage.clear()
            return location.reload()
        },
        createUser(){
            if(this.role === ''){
                this.erro_msg = `Choisir un role utilisateur`
                this.active_err = true
                return this.errorReset()
            }
            if(this.email === '' || this.password === ''){
                this.erro_msg = `Remplir tous les champs obligatoires (*)`
                this.active_err = true
                return this.errorReset()
            }

            if(!emailValidator(this.email)){
                this.erro_msg = `Email invalide`
                this.active_err = true
                return this.errorReset()
            }
            if(this.role === 'fol') this.api_url = `/api/fol-add`
            if(this.role === 'prefecture') this.api_url = `/api/prefec-add`
            if(this.role === 'staff') this.api_url = `/api/staff-add`

            const data = {
                email: this.email,
                password: this.password, 
                numero: this.numero,
                nom: this.nom,
                prenom: this.prenom,
            }

            axios.post(this.api_url, data, auth).then((res) => {
                if(res.status === 200){
                    this.succ_msg = 'Compte créé avec succès'
                    this.active_succ = true
                    this.successReset()
                    setTimeout(() => {
                        location.reload()
                    }, 1000)
                }
                else{
                    this.erro_msg = `Erreur de creation compte (compte existe, server error, etc...)`
                    this.active_err = true
                    return this.errorReset()
                }
            }).catch((err) => {
                this.erro_msg = `Erreur de creation compte (compte existe, server error, etc...)`
                this.active_err = true
                return this.errorReset()
            })
        },
        pickRole(admin, fol, prefecture, staff){
            let role 
            if(admin) role = 'Admin'
            if(fol) role = 'Fol'
            if(prefecture) role = 'Prefecture'
            if(staff) role = 'Staff'
            return role
        },
        deleteUser(id){
            if(confirm('Effacer?')){
                const data = {
                    id: id
                }

                axios.post('/api/delete-user', data, auth).then((res) => {
                    if(!res.status === 200){
                        this.erro_msg = `Erreur`
                        this.active_err = true
                        return this.errorReset()
                    }
                    this.succ_msg = 'Compte efface avec succès'
                    this.active_succ = true
                    this.successReset()
                    setTimeout(() => {
                        location.reload()
                    }, 1000)
                }).catch((err) => {
                    this.erro_msg = `Erreur`
                    this.active_err = true
                    return this.errorReset()
                })
            } 
        },
        modifyUser(){
            const data = {
                id: this.modify_id,
                email: this.modify_email,
                tel: this.modify_numero,
                nom: this.modify_nom,
                prenom: this.modify_prenom,
            }

            axios.post('/api/modify-user', data, auth).then((res) => {
                if(res.status != 200){
                    this.erro_msg = `Erreur`
                    this.active_err = true
                    return this.errorReset()
                }
                this.succ_msg = 'Compte modifie avec succès'
                this.active_succ = true
                this.successReset()
                setTimeout(() => {
                    location.reload()
                }, 1000)
            }).catch((err) => {
                this.erro_msg = `Erreur`
                this.active_err = true
                return this.errorReset()
            })
        },
        getModifyData(id, email, numero, nom, prenom){
            this.modify = true
            this.modify_id = id
            this.modify_email = email
            this.modify_numero = numero
            this.modify_nom = nom
            this.modify_prenom = prenom
        },
        modifyPass(id){
            if(confirm('Modifier le mot de passe?\nLe mot de passe sera généré automatiquement')){
                const data = {
                    id: id
                }
                
                axios.post('/api/regen-pwd', data, auth).then((res) => {
                    this.new_pwd = res.data.new_password
                    return this.password_changed = true
                }).catch((err) => {
                    this.erro_msg = `Erreur`
                    this.active_err = true
                    return this.errorReset()
                })
            }
        }
    },
    created(){
        axios.post('/api/get-bulk-users', {}, auth).then((res) => {
            this.users = res.data.users
        }).catch((err) => {
            return
        })
    }
})
</script>

<template>
    <div id="adduser-wrapper">
        <ErrMsg :active="active_err" :error_message="erro_msg"/>
        <SuccessMsg :active="active_succ" :success_message="succ_msg"/>
        <div id="btn-wrapper">
            <button id="add-usr-btn" @click="show_add = true">add user<i class='bx bxs-message-square-add'></i></button>
        </div>

        <Teleport to="div">
            <Transition name="slide-fade">
                <div id="add-user" v-if="show_add">    
                    <h2>Ajouter un utilisateur</h2>

                    <label for="email">Email: *</label>
                    <input type="text" v-model="email"> 
                    
                    <label for="password">Password: *</label>
                    <input type="text" v-model="password">
                    
                    <label for="Num Tel.">Numero Tel.</label>
                    <input type="text" v-model="numero">
                    
                    <label for="Nom">Nom:</label>
                    <input type="text" v-model="nom">
                    
                    <label for="Prenom">Prenom:</label>
                    <input type="text" v-model="prenom">
                    
                    <label for="role">Roles: *</label>
                    <select v-model="role">
                        <option value="fol">FOL</option>
                        <option value="prefecture">Prefecture</option>
                        <option value="staff">Staff</option>
                    </select>
                    <div id="submit-wrapper">
                        <button id="submit-btn" @click="createUser">Ajouter<i class='bx bx-plus' ></i></button>
                        <button id="back-btn" @click="show_add = false"><i class='bx bx-x bx-md'></i></button>
                    </div>
                </div>
            </Transition>
        </Teleport>

        <div id="user-list">
            <table>
                <tr>
                    <th>id</th>
                    <th>email</th>
                    <th>role</th>
                    <th>cration</th>
                    <th>dernier login</th>
                </tr>
                <tr v-for="i in users" :key="i">
                    <td>{{i.id}}</td>
                    <td>{{i.email}}</td>
                    <td>{{pickRole(i.is_admin, i.is_fol, i.is_prefec, i.is_staff)}}</td>
                    <td>{{i.created_at}}</td>
                    <td>{{i.last_login}}</td>
                    <div class="table-btn-wrapper">
                        <button class="table-btn-mod table-btn" @click="getModifyData(i.id, i.email, i.numero, i.nom, i.prenom)">Modifier</button>
                        <button class="table-btn-pwd table-btn" @click="modifyPass(i.id)">Changer Password</button>
                        <button class="table-btn-eff table-btn" v-if="!i.is_admin" @click="deleteUser(i.id)">Effacer</button>
                    </div>
                    
                </tr>
            </table>

            <Teleport to="div">
                <Transition name="slide-fade">
                    <div id="modify-user" v-if="modify">    
                        <h2>Modifier utilisateur</h2>

                        <label for="email">Email: *</label>
                        <input type="text" v-model="modify_email" :placeholder="modify_email"> 
                                
                        <label for="Num Tel.">Numero Tel.</label>
                        <input type="text" v-model="modify_numero" :placeholder="modify_numero">
                                
                        <label for="Nom">Nom:</label>
                        <input type="text" v-model="modify_nom" :placeholder="modify_nom">
                                
                        <label for="Prenom">Prenom:</label>
                        <input type="text" v-model="modify_prenom" :placeholder="modify_prenom">
                        <div id="submit-wrapper">
                            <button id="submit-btn" @click="modifyUser()">Modifier<i class='bx bx-plus' ></i></button>
                            <button id="back-btn" @click="modify = false"><i class='bx bx-x bx-md'></i></button>
                        </div>
                    </div>
                </Transition>
            </Teleport>   
            <Teleport to="div">
                <Transition name="slide-fade">
                    <div id="new-pwd" v-if="password_changed">    
                        <h2>Votre nouveau mot de passe</h2>
                        <p id="new-pass-p">{{new_pwd}}</p>
                        <button id="new-pwd-back-btn" @click="password_changed = false">Retour</button>
                    </div>
                </Transition>
            </Teleport>   
        </div>
    </div>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

#btn-wrapper{
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 1em;
    padding-inline: 2em;
}

#add-user{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    padding: 1%;
    flex-direction: column;
    width: 30em;
    border-radius: 10px;
    background: #0B6BA8;
}
h2{
    color: #ffffff;
}
input{
    margin-bottom: none;
    margin-bottom: 1em;
    padding: 0.5em;
    font-size: 1.2em;
}
label{
    text-align: start;
    font-weight: bold;
    color: #e6e6e6;
}
button{
    margin: auto;
    width: 10em;
    padding: 0.5em;
    font-size: 1em;
    border-radius: 50px;
    border: none;
    color: #ffffff;
}
#submit-wrapper{
    width: 100%;
    margin-top: 0.5em;
}
#submit-btn{
    background: #00ca4e;
    margin-inline: auto;
}
#submit-btn:hover{
    background: #00b344;
    transition: 0.1s;
}
#submit-btn:active{
    background: #00ca4e;
}
#back-btn{
    color: #ff605c;
    padding: 0;
    background: 0;
}
#back-btn:hover{
    background: #ff605c;
    color: #ffffff;
    transition: 0.1s;
}
#back-btn:active{
    background: none;
    color: #ff605c;
}
#exit-wrapper{
    display: flex;
    justify-content: end;
    align-items: center;
}
#add-usr-btn{
    background: #00ca4e;
    margin: 0;
    width: 10em;
    padding: 0.5em;
    font-size: 1em;
    border-radius: 50px;
}
#add-usr-btn:hover{
    background: #00b344;
    transition: 0.1s;
}
#add-usr-btn:active{
    background: #00ca4e;
}
i{
    vertical-align: middle;
    margin-left: 0.2em;
}
.bx-x{
    vertical-align: middle;
}
#submit-wrapper{
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 2em;
}
select{
    width:50%;
}




table{
    font-size: 0.8em;
    color: #000000;
    width: 100%;
    margin: auto;
}
td{
    border: solid 1px black;
}
.table-btn-wrapper{
    display: flex;
}
.table-btn:hover{
    color: #000000;
}
.table-btn:active{
    color: #ffffff;
}
.table-btn-eff{
    background: #ff605c;
}
.table-btn-mod{
    background: #ffbd44;
    margin-right: 1em;
}
.table-btn-pwd{
    background: #3C6A84;
    margin-right: 1em;
}


#modify-user{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    padding: 1%;
    flex-direction: column;
    width: 30em;
    border-radius: 10px;
    background: #0B6BA8;
}

#new-pwd{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    padding: 1%;
    flex-direction: column;
    width: 30em;
    border-radius: 10px;
    background: #0B6BA8;
}
#new-pwd-back-btn{
    background: #ffbd44;
}
#new-pwd-back-btn:hover{
    color: #000000;
}
#new-pwd-back-btn:active{
    color: #ffffff;
}
#new-pass-p{
    background: #ffffff;
    font-size: 1.5em;
    font-weight: bold;
}
</style>