<script>
const axios = require('axios')
import jwtDecode from "jwt-decode"

import { defineComponent } from 'vue'
import ErrMsg from '@/components/ErrMsg.vue'
import SuccessMsg from '@/components/SuccessMsg.vue'

export default defineComponent({
    name: 'Login',
    components : {
        ErrMsg,
        SuccessMsg,
    },
    data(){
      return{
        email: '',
        password: '',
        active_err: false,
        active_succ: false,
        erro_msg: '',
        succ_msg: '',
      }
    },
    methods: {
        errorReset(){
            if(this.active_err){
                setTimeout(() => {
                    this.active_err = !this.active_err
                }, 3000)
            }
        },
        successReset(){
            if(this.active_succ){
                setTimeout(() => {
                    this.active_succ = !this.active_succ
                }, 3000)
            }
        },
        login(){
            if(this.email === '' || this.password === ''){
                this.erro_msg = 'Remplir tous les champs obligatoires'
                this.active_err = true
                return this.errorReset()
            }

            const auth = {
                auth: {
                    username: this.email,
                    password: this.password
                }
            }
            axios.post('/api/login', {}, auth).then((res) => {
                if(res.status != 200) {
                    this.erro_msg = `Une erreur s'est produite veuillez réessayer plus tard`
                    this.active_err = true
                    return this.errorReset()
                }

                localStorage.setItem('token', res.data.access_token)

                const rawToken = jwtDecode(res.data.access_token)

                if(rawToken.is_admin) return window.location.href = '/admin'
                return window.location.href = '/dashboard'

            }).catch((err) => {
                console.log(err);
                this.erro_msg = `Erreur d'identifiant ou de mot de passe`
                this.active_err = true
                return this.errorReset()
            })
        },
    },
})
</script>

<template>
    <div id="wrapper">
            <ErrMsg :active="active_err" :error_message="erro_msg"/>
            <SuccessMsg :active="active_succ" :success_message="succ_msg"/>
            <div id="login-wrapper">
                <h2>Connectez-vous à votre espace 'nom projet'</h2>
                <p>Tous les champs sont obligatoires.</p>
                <label for="email">Identifiant</label>
                <input type="text" v-model="email" name="email">

                <label for="password">Mot de passe:</label>
                <input type="password" v-model="password" name="password">
                <button @click="login">Je me connecte<i class='bx bxs-chevrons-right bx-md'></i></button>
            </div>
    </div>
</template>

<style scoped>
#wrapper{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #EFF2FF;
}
#login-wrapper{
    display: flex;
    padding: 1%;
    flex-direction: column;
    width: 30em;
    background: #ffffff;
    border-radius: 10px;
}
h2{
    color: #0B6BA8;
}
input{
    margin-bottom: none;
    margin-bottom: 1em;
    padding: 0.5em;
    font-size: 1.5em;
}
label{
    text-align: start;
    font-weight: bold;
}
button{
    margin: auto;
    width: 15em;
    padding: 0.5em;
    font-size: 1.2em;
    border-radius: 50px;
    background: #0B6BA8;
    border: none;
    color: #ffffff;
}
button:hover{
    background: #5277AB;
    transition: 0.1s;
}
button:active{
    background: #0B6BA8;
}
i{
    margin-left: 0.2em;
    vertical-align: middle;
}
</style>