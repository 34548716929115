<script>
import { defineComponent } from 'vue'
import Header from '@/components/Dashboard/Header.vue'
import LateralBar from '@/components/Dashboard/LateralBar.vue'
import AddUser from '@/components/adminDash/AddUser.vue'

export default defineComponent({
    name: 'AdminDashboard',
    data(){
      return{

      }
    },
    components: {
        Header,
        LateralBar,
        AddUser,
    },
    methods: {

    },
})
</script>

<template>
    <div id="admin-wrapper">
        <LateralBar/>
        <div id="content">
            <AddUser/>
        </div>
    </div>
</template>

<style scoped>
#admin-wrapper{
    height: 100vh;
    display: grid;
    grid-template-columns: 19em 1fr;
}
</style>