import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '@/views/Login.vue'
import AdminRegister from '@/views/AdminRegister.vue'
import AdminDashboard from '@/views/AdminDashboard.vue'
import Dashboard from '@/views/Dashboard.vue'
import { createBlock } from 'vue'
import jwtDecode from "jwt-decode"
const axios = require("axios")



interface token_data {
  last_login: String,
  is_admin: Boolean,
  is_staff: Boolean,
  is_fol: Boolean,
  is_prefec: Boolean,
  iat: Number,
  exp: Number,
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      public: true
    },
    beforeEnter(to, from, next){
      tokenCheck(next)
    }
  },
  {
    path: '/admin-register',
    name: 'adminRegister',
    component: AdminRegister,
    meta: {
      public: true
    },
    beforeEnter(to, from, next){
      axios.post('/api/admin-check').then((res:any) => {
        if(res.status === 200) return next() 
      }).catch((err:any) => {
        return next('/')
      })
    },
  },
  {
    path: '/admin',
    name: 'adminDashboard',
    component: AdminDashboard,
    meta: {
      public: false
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      public: false
    },
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const  token  = localStorage.getItem('token')

  const tokenData = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  if (!to.meta.public){
    if(!token) {
      localStorage.clear()
      return next('/')
    }
    await axios.post('/api/token-check', {}, tokenData).then( async (res:any) => {
      const rawToken :token_data = jwtDecode(token)
      if(rawToken.is_admin && to.path === '/admin') return next()
      if(rawToken.is_fol && to.path === '/dashboard') return next()
      if(rawToken.is_prefec && to.path === '/dashboard') return next()
      localStorage.clear()
      return next('/')
    }).catch((err:any) => {
      localStorage.clear()
      return next('/')
    })
  }
  else{
    next()
  }
})

const tokenCheck = async (next:any) => {
  const  token  = localStorage.getItem('token')
  const tokenData = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  if(!token) {
    localStorage.clear()
    return next()
  }

  if(token){
    await axios.post('/api/token-check', {}, tokenData).then((res:any) => {

      const rawToken :token_data = jwtDecode(token)
      if(res.status == 200 && rawToken.is_admin) return next('/admin')
      if(res.status == 200 && rawToken.is_fol) return next('/dashboard')
      if(res.status == 200 && rawToken.is_prefec) return next('/dashboard')
    }).catch((err:any) => {
      localStorage.clear()
      return next()
    })
  }
}

export default router
