<script>
import { defineComponent } from 'vue'
import Header from '@/components/Dashboard/Header.vue'
import LateralBar from '@/components/Dashboard/LateralBar'
import FolDash from '@/components/Dashboard/FolDash.vue'
import PrefecDash from '@/components/Dashboard/PrefecDash.vue'
import jwtDecode from "jwt-decode"

export default defineComponent({
    name: 'Dashboard',
    data(){
      return{
        showFol: false,
        showPrefec: false,
        cityId: '',
        cityName: '',

                // collectivites
        nb_offres_logements_coll: null,
        nb_places_coll: null,
        nb_logements_pris_coll: null,
        nb_personnes_installees_coll: null,
        nbre_de_pers_en_cours_de_relogement_coll: null,
        nb_refus_familles_coll: null,
        nb_refus_personne_coll: null,

        // entreprises-asso
        nb_offres_de_logements_entr: null,
        nb_places_entr: null,
        nbre_de_pers_en_cours_de_relogement_entr: null,
        nb_refus_familles_entr: null,
        nb_refus_personne_entr: null,


        // bailleurs
        nb_offre_de_logements_bail: null,
        nb_de_places_bail: null,
        nb_logement_pris_bail: null,
        nb_personnes_installees_bail: null,
        nbre_de_pers_en_cours_de_relogement_bail: null,
        nb_refus_familles_bail: null,
        nb_refus_personne_bail: null,

        // CHRS
        nb_ukrainiens_installes_chrs: null,
        dont_x_en_cours_de_relogement_chrs: null,
        dont_x_refus_aps_chrs: null,
        nb_refus_familles_chrs: null,
        nb_refus_personne_chrs: null,

        // particuliers
        nb_offres_particuliers_part: null,
        nb_de_logements_independants_particuliers_part: null,
        nb_places_logements_independants_particuliers_part: null,
        nb_ukrainiens_installes_chez_des_particuliers_avec_iml_part: null,
        nb_ukrainiens_demandant_a_etre_reloges_part: null,
        visite_par_la_fol_part: null,
        nb_refus_familles_part: null,
        nb_refus_personne_part: null,

        old_observations: [],
      }  
    },
    components: {
        Header,
        LateralBar,
        FolDash,
        PrefecDash,
    },
    methods: {
        getCityData(event){
            this.cityId = event.cityId
            this.cityName = event.cityName

            this.nb_places_coll = event.nb_places_coll
            this.nb_offres_logements_coll = event.nb_offres_logements_coll
            this.nb_logements_pris_coll = event.nb_logements_pris_coll
            this.nb_personnes_installees_coll = event.nb_personnes_installees_coll
            this.nbre_de_pers_en_cours_de_relogement_coll = event.nbre_de_pers_en_cours_de_relogement_coll
            this.nb_refus_familles_coll = event.nb_refus_familles_coll
            this.nb_refus_personne_coll = event.nb_refus_personne_coll

            this.nb_offres_de_logements_entr = event.nb_offres_de_logements_entr
            this.nb_places_entr = event.nb_places_entr
            this.nbre_de_pers_en_cours_de_relogement_entr = event.nbre_de_pers_en_cours_de_relogement_entr
            this.nb_refus_familles_entr = event.nb_refus_familles_entr
            this.nb_refus_personne_entr = event.nb_refus_personne_entr

            this.nb_offre_de_logements_bail = event.nb_offre_de_logements_bail
            this.nb_de_places_bail = event.nb_de_places_bail
            this.nb_logement_pris_bail = event.nb_logement_pris_bail
            this.nb_personnes_installees_bail = event.nb_personnes_installees_bail
            this.nbre_de_pers_en_cours_de_relogement_bail = event.nbre_de_pers_en_cours_de_relogement_bail
            this.nb_refus_familles_bail = event.nb_refus_familles_bail
            this.nb_refus_personne_bail = event.nb_refus_personne_bail

            this.nb_ukrainiens_installes_chrs = event.nb_ukrainiens_installes_chrs
            this.dont_x_en_cours_de_relogement_chrs = event.dont_x_en_cours_de_relogement_chrs
            this.dont_x_refus_aps_chrs = event.dont_x_refus_aps_chrs
            this.nb_refus_familles_chrs = event.nb_refus_familles_chrs
            this.nb_refus_personne_chrs = event.nb_refus_personne_chrs

            this.nb_offres_particuliers_part = event.nb_offres_particuliers_part
            this.nb_de_logements_independants_particuliers_part = event.nb_de_logements_independants_particuliers_part
            this.nb_places_logements_independants_particuliers_part = event.nb_places_logements_independants_particuliers_part
            this.nb_ukrainiens_installes_chez_des_particuliers_avec_iml_part = event.nb_ukrainiens_installes_chez_des_particuliers_avec_iml_part
            this.nb_ukrainiens_demandant_a_etre_reloges_part = event.nb_ukrainiens_demandant_a_etre_reloges_part
            this.visite_par_la_fol_part = event.visite_par_la_fol_part
            this.nb_refus_familles_part = event.nb_refus_familles_part
            this.nb_refus_personne_part = event.nb_refus_personne_part

            return
        },
        
    },
    created(){
        const rawToken = jwtDecode(localStorage.getItem('token'))
        if(rawToken.is_fol) return this.showFol = true
        if(rawToken.is_prefec) return this.showPrefec = true
    }
})
</script>

<template>
    <div id="dash-wrapper">
        <LateralBar @city-data="getCityData"/>
        <div id="content">
            <FolDash v-if="showFol" 
            :cityId="this.cityId" 
            :cityName="this.cityName"
            :nbOffresLogementsColl="this.nb_offres_logements_coll"
            :nbPlacesColl="this.nb_places_coll"
            :nbLogementsPrisColl="this.nb_logements_pris_coll"
            :nbPersonnesInstalleesColl="this.nb_personnes_installees_coll"
            :nbreDePersEnCoursDeRelogementColl="this.nbre_de_pers_en_cours_de_relogement_coll"
            :nbRefusFamillesColl="this.nb_refus_familles_coll"
            :nbRefusPersonneColl="this.nb_refus_personne_coll"


            :nbOffresDeLogementsEntr="this.nb_offres_de_logements_entr"
            :nbPlacesEntr="this.nb_places_entr"
            :nbreDePersEnCoursDeRelogementEntr="this.nbre_de_pers_en_cours_de_relogement_entr"
            :nbRefusFamillesEntr="this.nb_refus_familles_entr"
            :nbRefusPersonneEntr="this.nb_refus_personne_entr"

            :nbOffreDeLogementsBail="this.nb_offre_de_logements_bail"
            :nbDePlacesBail="this.nb_de_places_bail"
            :nbLogementPrisBail="this.nb_logement_pris_bail"
            :nbPersonnesInstalleesBail="this.nb_personnes_installees_bail"
            :nbreDePersEnCoursDeRelogementBail="this.nbre_de_pers_en_cours_de_relogement_bail"
            :nbRefusFamillesBail="this.nb_refus_familles_bail"
            :nbRefusPersonneBail="this.nb_refus_personne_bail"

            :nbUkrainiensInstallesChrs="this.nb_ukrainiens_installes_chrs"
            :dontXEnCoursDeRelogementChrs="this.dont_x_en_cours_de_relogement_chrs"
            :dontXRefusApsChrs="this.dont_x_refus_aps_chrs"
            :nbRefusFamillesChrs="this.nb_refus_familles_chrs"
            :nbRefusPersonneChrs="this.nb_refus_personne_chrs"

            :nbOffresParticuliersPart="this.nb_offres_particuliers_part"
            :nbDeLogementsIndependantsParticuliersPart="this.nb_de_logements_independants_particuliers_part"
            :nbPlacesLogementsIndependantsParticuliersPart="this.nb_places_logements_independants_particuliers_part"
            :nbUkrainiensInstallesChezDesParticuliersAvecImlPart="this.nb_ukrainiens_installes_chez_des_particuliers_avec_iml_part"
            :nbUkrainiensDemandantAEtreRelogesPart="this.nb_ukrainiens_demandant_a_etre_reloges_part"
            :visiteParLaFolPart="this.visite_par_la_fol_part"
            :nbRefusFamillesPart="this.nb_refus_familles_part"
            :nbRefusPersonnePart="this.nb_refus_personne_part"
            />
            <PrefecDash v-if="showPrefec"/>
        </div>
    </div>
</template>

<style scoped>
#dash-wrapper{
    height: 100vh;
    display: grid;
    grid-template-columns: 19em 1fr;
}
#content{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>