<script>
import { defineComponent } from 'vue'
import jwtDecode from "jwt-decode"
import { calcWeek, calcYear } from './calcDate'
import { jsPDF } from "jspdf"

const axios = require('axios')



const auth = {
    headers : {
        authorization: `Bearer ${localStorage.getItem('token')}`
    }
}

export default defineComponent({
    name: 'LateralBar',
    data(){
      return{
        chargement: '',
        departments: [],
        cities: [],
        pic: '',
        tableData: [],
        is_prefec: false,
        showpdf: false,
        nowDate: '',
        week: calcWeek(),
        year: calcYear(),

        tot_nb_places_coll: null,
        tot_nb_offres_logements_coll: null,
        tot_nb_logements_pris_coll: null,
        tot_nb_personnes_installees_coll: null,
        tot_nbre_de_pers_en_cours_de_relogement_coll: null,
        tot_nb_refus_familles_coll: null,
        tot_nb_refus_personne_coll: null,

 
        tot_nb_offres_de_logements_entr: null,
        tot_nb_places_entr: null,
        tot_nbre_de_pers_en_cours_de_relogement_entr: null,
        tot_nb_refus_familles_entr: null,
        tot_nb_refus_personne_entr: null,  


        tot_nb_offre_de_logements_bail: null,
        tot_nb_de_places_bail: null,
        tot_nb_logement_pris_bail: null,
        tot_nb_personnes_installees_bail: null,
        tot_nbre_de_pers_en_cours_de_relogement_bail: null,
        tot_nb_refus_familles_bail: null,
        tot_nb_refus_personne_bail: null,


        tot_nb_ukrainiens_installes_chrs: null,
        tot_dont_x_en_cours_de_relogement_chrs: null,
        tot_dont_x_refus_aps_chrs: null,
        tot_nb_refus_familles_chrs: null,
        tot_nb_refus_personne_chrs: null,


        tot_nb_offres_particuliers_part: null,
        tot_nb_de_logements_independants_particuliers_part: null,
        tot_nb_places_logements_independants_particuliers_part: null,
        tot_nb_ukrainiens_installes_chez_des_particuliers_avec_iml_part: null,
        tot_nb_ukrainiens_demandant_a_etre_reloges_part: null,
        tot_visite_par_la_fol_part: null,
        tot_nb_refus_familles_part: null,
        tot_nb_refus_personne_part: null,

      }
    },
    methods: {
        downloadData(){
            axios.post('/api/get-all', {}, auth).then((result) => {
                
                this.tableData = result.data

                let chrs = this.tableData.filter((element) => {
                    return element.type === 'chrs'
                })
                for(const value of chrs){
                    this.tot_nb_ukrainiens_installes_chrs += value.nb_ukrainiens_installés_chrs
                    this.tot_dont_x_en_cours_de_relogement_chrs += value.dont_x_en_cours_de_relogemen_chrs
                    this.tot_dont_x_refus_aps_chrs += value.dont_x_refus_aps_chrs
                    this.tot_nb_refus_familles_chrs += value.nb_refus_famille_chrs
                    this.tot_nb_refus_personne_chrs += value.nb_refus_personne_chrs
                }
                

                let part = this.tableData.filter((element) => {
                    return element.type === 'part'
                })
                for(const value of part){
                    this.tot_nb_offres_particuliers_part += value.nb_offres_particuliers
                    this.tot_nb_de_logements_independants_particuliers_part += value.nb_de_logements_indépendants_particuliers
                    this.tot_nb_places_logements_independants_particuliers_part += value.nb_places_logements_indépendants_particuliers
                    this.tot_nb_ukrainiens_installes_chez_des_particuliers_avec_iml_part += value.nb_ukrainiens_installés_chez_des_particuliers_avec_iml
                    this.tot_nb_ukrainiens_demandant_a_etre_reloges_part += value.nb_ukrainiens_demandant_à_être_relogés_part
                    this.tot_visite_par_la_fol_part += value.visité_par_la_fol_part
                    this.tot_nb_refus_familles_part += value.nb_refus_famille_part
                    this.tot_nb_refus_personne_part += value.nb_refus_personne_part
                }
                

                let bail = this.tableData.filter((element) => {
                    return element.type === 'bail'
                })
                for(const value of bail){
                    this.tot_nb_offre_de_logements_bail += value.nb_offres_de_logements_bail
                    this.tot_nb_de_places_bail += value.nb_places_bail
                    this.tot_nb_logement_pris_bail += value.nb_logements_pris_bail
                    this.tot_nb_personnes_installees_bail += value.nb_personnes_installées_bail
                    this.tot_nbre_de_pers_en_cours_de_relogement_bail += value.nbre_de_pers_en_cours_de_relogemen_bail
                    this.tot_nb_refus_familles_bail += value.nb_refus_famille_bail
                    this.tot_nb_refus_personne_bail += value.nb_refus_personne_bail
                }

                let coll = this.tableData.filter((element) => {
                    return element.type === 'coll'
                })
                for(const value of coll){
                    this.tot_nb_places_coll += value.nb_places_coll
                    this.tot_nb_offres_logements_coll += value.nb_offres_de_logements_coll
                    this.tot_nb_logements_pris_coll += value.nb_logements_pris_coll
                    this.tot_nb_personnes_installees_coll += value.nb_personnes_installées_coll
                    this.tot_nbre_de_pers_en_cours_de_relogement_coll += value.nbre_de_pers_en_cours_de_relogemen_coll
                    this.tot_nb_refus_familles_coll += value.nb_refus_famille_coll
                    this.tot_nb_refus_personne_coll += value.nb_refus_personne_coll
                }
                

                let entr = this.tableData.filter((element) => {
                    return element.type === 'entr'
                })
                for(const value of entr){
                    this.tot_nb_offres_de_logements_entr += value.nb_offres_de_logements_entr
                    this.tot_nb_places_entr += value.nb_places_entr
                    this.tot_nbre_de_pers_en_cours_de_relogement_entr += value.nbre_de_pers_en_cours_de_relogemen_entr
                    this.tot_nb_refus_familles_entr += value.nb_refus_famille_entr
                    this.tot_nb_refus_personne_entr += value.nb_refus_personne_entr
                }
                this.showpdf = true
            }).catch((err) => {
                console.log(err)
                return
            })
        },
        async downloadPDF(){
            this.chargement = 'Chargement en cours...'

            const doc = new jsPDF()
            const pageHeight = doc.internal.pageSize.height

            console.log(pageHeight)
            await doc.html(document.querySelector("#pdf"), {
                callback: (doc) => {
                    doc.addPage()
                    doc.save(`résultat_fol_${this.nowDate}.pdf`)
                },
                x: 0,
                y: 297,
                width: 170,
                windowWidth: 650,
            })

            return this.chargement = ''
        },
        logout(){
            localStorage.clear()
            return location.reload()
        },
        tableFilter(city_id){

            const filtered = this.tableData.filter(data => {
                return data.ville_id === city_id
            })
            return filtered
        },
        filterCity(dep_id){
            const filtered = this.cities.filter(city => {
                return city.id_arrondissement === dep_id
            })
            return filtered
        },
        emitCity(city_id, city_name){

            const data = {
                city_id: city_id,
                week: calcWeek(),
                year: calcYear(),
            }
            // get all the data to emit at each city click (all categories data + date + year to match)
            axios.post('/api/get-city-data', data, auth).then((res) => {
                return this.$emit('cityData', {
                    cityId: city_id,
                    cityName: city_name,
                    
                    nb_places_coll: res.data.Nb_places_coll,
                    nb_offres_logements_coll: res.data.Nb_offres_de_logements_coll,
                    nb_logements_pris_coll: res.data.Nb_logements_pris_coll,
                    nb_personnes_installees_coll: res.data.Nb_personnes_installées_coll,
                    nbre_de_pers_en_cours_de_relogement_coll: res.data.Nbre_de_pers_en_cours_de_relogemen_coll,
                    nb_refus_familles_coll: res.data.Nb_refus_famille_coll,
                    nb_refus_personne_coll: res.data.Nb_refus_personne_coll,

 
                    nb_offres_de_logements_entr: res.data.Nb_offres_de_logements_entr,
                    nb_places_entr: res.data.Nb_places_entr,
                    nbre_de_pers_en_cours_de_relogement_entr: res.data.Nbre_de_pers_en_cours_de_relogemen_entr,
                    nb_refus_familles_entr: res.data.Nb_refus_famille_entr,
                    nb_refus_personne_entr: res.data.Nb_refus_personne_entr,


                    nb_offre_de_logements_bail: res.data.Nb_offres_de_logements_bail,
                    nb_de_places_bail: res.data.Nb_places_bail,
                    nb_logement_pris_bail: res.data.Nb_logements_pris_bail,
                    nb_personnes_installees_bail: res.data.Nb_personnes_installées_bail,
                    nbre_de_pers_en_cours_de_relogement_bail: res.data.Nbre_de_pers_en_cours_de_relogemen_bail,
                    nb_refus_familles_bail: res.data.Nb_refus_famille_bail,
                    nb_refus_personne_bail: res.data.Nb_refus_personnes_bail,


                    nb_ukrainiens_installes_chrs: res.data.Nb_Ukrainiens_installés_chrs,
                    dont_x_en_cours_de_relogement_chrs: res.data.Dont_x_en_cours_de_relogemen_chrs,
                    dont_x_refus_aps_chrs: res.data.Dont_x_refus_APS_chrs,
                    nb_refus_familles_chrs: res.data.Nb_refus_famille_chrs,
                    nb_refus_personne_chrs: res.data.Nb_refus_personne_chrs,


                    nb_offres_particuliers_part: res.data.Nb_offres_particuliers,
                    nb_de_logements_independants_particuliers_part: res.data.Nb_de_logements_indépendants_particuliers,
                    nb_places_logements_independants_particuliers_part: res.data.Nb_places_logements_indépendants_particuliers,
                    nb_ukrainiens_installes_chez_des_particuliers_avec_iml_part: res.data.Nb_Ukrainiens_installés_chez_des_particuliers_avec_iml,
                    nb_ukrainiens_demandant_a_etre_reloges_part: res.data.Nb_Ukrainiens_demandant_à_être_relogés,
                    visite_par_la_fol_part: res.data.Visité_par_la_fol,
                    nb_refus_familles_part: res.data.Nb_refus_famille_part,
                    nb_refus_personne_part: res.data.Nb_refus_personne_part,

                })
            }).catch((err) => {
                return
            })
        },
        closeModal(){
            this.showpdf = false
            return window.location.reload()
        },
    },
    async created(){
        const date = new Date()
        const format = date.toLocaleString('fr-FR', {timeZone: 'Europe/Paris'}).split(' ')[0]
        this.nowDate = format.replace(/\./g, '_')

        await axios.post('/api/get-dep', {}, auth).then((res) => {
            this.departments = res.data.arrondissements
            axios.post('/api/get-cities', {}, auth).then((res) => {
                this.cities = res.data.cities
            }).catch((err) => {
                return
            })
        }).catch((err) => {
            return
        })

        const rawToken = jwtDecode(localStorage.getItem('token'))
        if(rawToken.is_prefec) this.is_prefec = true
        if(rawToken.is_admin) return this.pic = 'https://i.ibb.co/k9NWd0J/security.png'
        if(rawToken.is_fol) return this.pic = 'https://i.ibb.co/n312GgT/logo-fol-190.png'
        if(rawToken.is_prefec) return this.pic = 'https://i.ibb.co/rQZ6BRp/Logo-republique-francaise.png'
        if(rawToken.is_staff) return this.pic = 'https://i.ibb.co/qnNVYv5/software-engineer.png'
    },
})
</script>

<template>
    <div id="lateral-wrapper">
        <div id="pic-wrapper">
            <img id="image" :src="pic" alt="1:1 ratio">
        </div>

        <div v-if="showpdf" id="pdf-wrapper">
            <button @click="downloadPDF" class="btn-download">Telecharger</button>
            <button @click="closeModal" class="btn-exit"><i class='bx bx-x bx-sm'></i></button>
            <p>{{chargement}}</p>
            
            <div id="pdf" ref="doc" style="font-size: 0.8em; color: #1b2631; background: white; display: block; width: 210mm; margin: 0 auto; text-align: center; font-family: Nunito;">
                <h2>Résultats FOL ({{nowDate}}) semaine ({{week}}-{{year}})</h2>     
                <div id="total-wrapper" style="height: 297mm">
                    <h3>Total</h3>
                    <h4>TOT Collectivités</h4>
                    <table>
                        <tr>
                            <th>TOT Nb offres logements</th>
                            <th>TOT Nb places</th>
                            <th>TOT Nb logements pris</th>
                            <th>TOT Nb personnes installees</th>
                            <th>TOT Nbre de pers en cours de relogement</th>
                            <th>TOT Nb refus familles</th>
                            <th>TOT Nb refus personne</th>
                        </tr>
                        <tr>  
                            <td>{{tot_nb_offres_logements_coll}}</td>
                            <td>{{tot_nb_places_coll}}</td>
                            <td>{{tot_nb_logements_pris_coll}}</td>
                            <td>{{tot_nb_personnes_installees_coll}}</td>
                            <td>{{tot_nbre_de_pers_en_cours_de_relogement_coll}}</td>
                            <td>{{tot_nb_refus_familles_coll}}</td>
                            <td>{{tot_nb_refus_personne_coll}}</td>
                        </tr>
                    </table>

                    <h4>TOT Entreprises-Association</h4>
                    <table>
                        <tr>
                            <th>TOT Nb offres de logements</th>
                            <th>TOT Nb places</th>
                            <th>TOT Nbre de pers en cours de relogement</th>
                            <th>TOT Nb refus familles</th>
                            <th>TOT Nb refus personne</th>
                        </tr>
                        <tr>
                            <td>{{tot_nb_offres_de_logements_entr}}</td>
                            <td>{{tot_nb_places_entr}}</td>
                            <td>{{tot_nbre_de_pers_en_cours_de_relogement_entr}}</td>
                            <td>{{tot_nb_refus_familles_entr}}</td>
                            <td>{{tot_nb_refus_personne_entr}}</td>
                        </tr>
                    </table>

                    <h4>TOT Bailleurs</h4>
                    <table>
                        <tr>
                            <th>TOT Nb offre de logements</th>
                            <th>TOT Nb de places</th>
                            <th>TOT Nb logements pris</th>
                            <th>TOT Nb personnes installees</th>
                            <th>TOT Nbre de pers en cours de relogement</th>
                            <th>TOT Nb refus familles</th>
                            <th>TOT Nb refus personne</th>
                        </tr>
                        <tr>
                            <td>{{tot_nb_offre_de_logements_bail}}</td>
                            <td>{{tot_nb_de_places_bail}}</td>
                            <td>{{tot_nb_logement_pris_bail}}</td>
                            <td>{{tot_nb_personnes_installees_bail}}</td>
                            <td>{{tot_nbre_de_pers_en_cours_de_relogement_bail}}</td>
                            <td>{{tot_nb_refus_familles_bail}}</td>
                            <td>{{tot_nb_refus_personne_bail}}</td>
                        </tr>
                    </table>

                    <h4>TOT CHRS</h4>
                    <table>
                        <tr>
                            <th>TOT Nb ukrainiens installes</th>
                            <th>TOT dont x en cours de relogement</th>
                            <th>TOT dont x refus aps</th>
                            <th>TOT Nb refus familles</th>
                            <th>TOT Nb refus personne</th>
                        </tr>
                        <tr>
                            <td>{{tot_nb_ukrainiens_installes_chrs}}</td>
                            <td>{{tot_dont_x_en_cours_de_relogement_chrs}}</td>
                            <td>{{tot_dont_x_refus_aps_chrs}}</td>
                            <td>{{tot_nb_refus_familles_chrs}}</td>
                            <td>{{tot_nb_refus_personne_chrs}}</td>
                        </tr>
                    </table>


                    <h4>TOT Particuliers</h4>
                        <table>
                            <tr>
                                <th>TOT Nb offres particuliers</th>
                                <th>TOT Nb de logements indépendants particuliers</th>
                                <th>TOT Nb places logements indépendants particuliers</th>
                                <th>TOT Nb Ukrainiens installés chez des particuliers avec IML</th>
                                <th>TOT Nb Ukrainiens demandant à être relogés</th>
                                <th>TOT Visité par la FOL</th>
                                <th>TOT Nb refus familles</th>
                                <th>TOT Nb refus personne</th>
                            </tr>
                            <tr>
                                <td>{{tot_nb_offres_particuliers_part}}</td>
                                <td>{{tot_nb_de_logements_independants_particuliers_part}}</td>
                                <td>{{tot_nb_places_logements_independants_particuliers_part}}</td>
                                <td>{{tot_nb_ukrainiens_installes_chez_des_particuliers_avec_iml_part}}</td>
                                <td>{{tot_nb_ukrainiens_demandant_a_etre_reloges_part}}</td>
                                <td>{{tot_visite_par_la_fol_part}}</td>
                                <td>{{tot_nb_refus_familles_part}}</td>
                                <td>{{tot_nb_refus_personne_part}}</td>
                            </tr>
                        </table>
                </div>


        
                <div v-for="i in cities" :key="i" class="pdf-inner" style="height: 297mm">
                    <h3>{{i.name}}</h3>
                    
                    <h4>Collectivités</h4>
                    <table>
                        <tr>
                            <th>Nb offres de logements</th>
                            <th>Nb places</th>
                            <th>Nb logements pris</th>
                            <th>Nb personnes installées</th>
                            <th>Nbre de pers en cours de relogement</th>
                            <th>Nb refus familles</th>
                            <th>Nb refus personne</th>
                        </tr>
                        <tr v-for="(data) in tableFilter(i.id)" :key="data">
                            <td>{{data.nb_offres_de_logements_coll}}</td>
                            <td>{{data.nb_places_coll}}</td>
                            <td>{{data.nb_logements_pris_coll}}</td>
                            <td>{{data.nb_personnes_installées_coll}}</td>
                            <td>{{data.nbre_de_pers_en_cours_de_relogemen_coll}}</td>
                            <td>{{data.nb_refus_famille_coll}}</td>
                            <td>{{data.nb_refus_personne_coll}}</td>
                        </tr>
                    </table>

                    <h4>Entreprises-Association</h4>
                    <table>
                        <tr>
                            <th>Nb offres de logements</th>
                            <th>Nb places</th>
                            <th>Nbre de pers en cours de relogement</th>
                            <th>Nb refus familles</th>
                            <th>Nb refus personne</th>
                        </tr>
                        <tr v-for="(data) in tableFilter(i.id)" :key="data">
                            <td>{{data.nb_offres_de_logements_entr}}</td>
                            <td>{{data.nb_places_entr}}</td>
                            <td>{{data.nbre_de_pers_en_cours_de_relogemen_entr}}</td>
                            <td>{{data.nb_refus_famille_entr}}</td>
                            <td>{{data.nb_refus_personne_entr}}</td>
                        </tr>
                    </table>

                    <h4>Bailleurs</h4>
                    <table>
                        <tr>
                            <th>Nb offres de logements</th>
                            <th>Nb places</th>
                            <th>Nb logements pris</th>
                            <th>Nb personnes installées</th>
                            <th>Nbre de pers en cours de relogement</th>
                            <th>Nb refus familles</th>
                            <th>Nb refus personne</th>
                        </tr>
                        <tr v-for="(data) in tableFilter(i.id)" :key="data">
                            <td>{{data.nb_offres_de_logements_bail}}</td>
                            <td>{{data.nb_places_bail}}</td>
                            <td>{{data.nb_logements_pris_bail}}</td>
                            <td>{{data.nb_personnes_installées_bail}}</td>
                            <td>{{data.nbre_de_pers_en_cours_de_relogemen_bail}}</td>
                            <td>{{data.nb_refus_famille_bail}}</td>
                            <td>{{data.nb_refus_personne_bail}}</td>
                        </tr>
                    </table>

                    <h4>CHRS</h4>
                    <table>
                        <tr>
                            <th>Nb Ukrainiens installés</th>
                            <th>Dont x en cours de relogement</th>
                            <th>Dont x refus APS</th>
                            <th>Nb refus familles</th>
                            <th>Nb refus personne</th>
                        </tr>
                        <tr v-for="(data) in tableFilter(i.id)" :key="data">
                            <td>{{data.nb_ukrainiens_installés_chrs}}</td>
                            <td>{{data.dont_x_en_cours_de_relogemen_chrs}}</td>
                            <td>{{data.dont_x_refus_aps_chrs}}</td>
                            <td>{{data.nb_refus_famille_chrs}}</td>
                            <td>{{data.nb_refus_personne_chrs}}</td>
                        </tr>
                    </table>

                    <h4>Particuliers</h4>
                    <table>
                        <tr>
                            <th>Nb offres particuliers</th>
                            <th>Nb de logements indépendants particuliers</th>
                            <th>Nb places logements indépendants particuliers</th>
                            <th>Nb Ukrainiens installés chez des particuliers avec IML</th>
                            <th>Nb Ukrainiens demandant à être relogés</th>
                            <th>Visité par la FOL</th>
                            <th>Nb refus familles</th>
                            <th>Nb refus personne</th>
                        </tr>
                        <tr v-for="(data) in tableFilter(i.id)" :key="data">
                            <td>{{data.nb_offres_particuliers}}</td>
                            <td>{{data.nb_de_logements_indépendants_particuliers}}</td>
                            <td>{{data.nb_places_logements_indépendants_particuliers}}</td>
                            <td>{{data.nb_ukrainiens_installés_chez_des_particuliers_avec_iml}}</td>
                            <td>{{data.nb_ukrainiens_demandant_à_être_relogés_part}}</td>
                            <td>{{data.visité_par_la_fol_part}}</td>
                            <td>{{data.nb_refus_famille_part}}</td>
                            <td>{{data.nb_refus_personne_part}}</td>
                        </tr>
                    </table>
                    <h4>observations</h4>
                    <div v-for="(data) in tableFilter(i.id)" :key="data">
                        <p v-for="(i) in data.content" :key="i" class="obs">{{i}}</p>
                    </div>
                </div>
            </div>
        </div>
        

        <button id="deconn-btn" @click="logout"><i class='bx bx-log-out bx-sm'></i>Se déconnecter</button>
        <button id="download-btn" v-if="is_prefec" @click="downloadData">Tout telecharger</button>

        <div class="geo-wrapper"  v-for="(dep) in departments" :key="dep" v-if="!is_prefec">
            <button class="dep-name">{{dep.name}}</button>
            <div class="city-list-wrapper">
                <ul>
                    <li class="city-li" v-for="city in filterCity(dep.id)" :key="city.id"><button class="city-btn" @click="emitCity(city.id, city.name)">{{city.name}} <i class='bx bx-refresh bx-xs'></i></button></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>
.obs{
    margin-bottom: 0;
    font-weight: bold;
    color: #000000;
}
#pdf-wrapper{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #1b2631;
    background: #cbd7e6;
    font-family: Nunito;
    text-align: center;
    font-size: 0.9em;
    height: 100%;
    width: 100%;
    overflow: auto;
    border-radius: 15px;
}

table{
    margin-inline: auto;
    margin-block: 0;
    border-bottom: solid #1b2631 1px;
}
th{
    background: #324B67;
    color: white;
    padding: 0.2em;
    font-weight: 100;
}
td{
    font-weight: bold;
    font-size: 1.4em;
    color: #009933;
}
h3{
    font-weight: bold;
    font-size: 2em;
}
h4{
    font-size: 1.2em;
    margin-bottom: 0;
    font-weight: bold;
    text-transform: uppercase;
    color: #0B6BA8;
    width: 100%;
}

#lateral-wrapper{
    width: 17em;
    padding: 1em;
    margin: 0;
    background: #0B6BA8;
    overflow: auto;
}

#pic-wrapper{
    width: 8em;
    margin: auto;
    overflow: hidden;
}
#image{
    height: 100%;
    width: 100%;
}

#deconn-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    text-align: center;
    height: 3em;
    width: 80%;
    border-radius: 25px;
    background: #eeeeee;
    color: #ff605c;
    font-weight: bold;
    margin: auto;
    margin-block: 2em;
    padding: 0.5em;
}
i{
    margin-right: 0.2em;
    vertical-align: middle;
}

.btn-download{
    margin: auto;
    background: #00ca4e;
    padding: 0.5em;
    margin: 0.5em;
    outline: none;
    border: none;
    border-radius: 15px;
    color: #ffffff;
}
.btn-exit{
    margin: auto;
    background: none;
    outline: none;
    border: none;
    padding: 0.5em;
    margin: 0.5em;
    color: #ff605c;
}
.btn-download:hover{
    background: #00ca4e;
    color: #000000;
}
.btn-exit:hover{
    color: #ffffff;
}

.dep-name{
    color: #1b2631;
    font-size: 1.1em;
    font-weight: bold;
    background: none;
    border: none;
    outline: none;
    margin: 0.5em;
    margin-bottom: 0;
}

ul{
    padding: 0;
}
.city-li{
    list-style-type: none;
}
.city-btn{
    font-size: 0.9em;
    background: #7593bd;
    display: flex;
    justify-content: start;
    text-align: start;
    color: #ffffff;
    border: none;
    outline: none;
    margin-block: 0.2em;
    padding: 0.3em;
    width: 100%;
    border-radius: 5px;
}
.city-btn:hover{
    background: #0B6BA8;
    color: #1b2631;
}
.city-btn:active{
    background: #7593bd;
    color: #ffffff;
}

.bx-refresh{
    margin-left: auto;
    vertical-align: middle;
}
#download-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    text-align: center;
    height: 3em;
    width: 80%;
    border-radius: 25px;
    background: #eeeeee;
    color: #00ca4e;
    font-weight: bold;
    margin: auto;
    margin-block: 2em;
    padding: 0.5em;
}
</style>