<script>
import { defineComponent } from 'vue'
import { calcWeek, calcYear } from './calcDate'
import ErrMsg from '@/components/ErrMsg.vue'
import SuccessMsg from '@/components/SuccessMsg.vue'

const axios = require('axios')

const auth = {
    headers : {
        authorization: `Bearer ${localStorage.getItem('token')}`
    }
}

export default defineComponent({
    name: 'PrefecDash',
    props: {

    },
    data(){
      return{
        empty: false,
        nowDate: '',
        nowWeek: calcWeek(),
        nowYear: calcYear(),
        data: [],
        departments: [],
        cities: [],
        showModal: false,
        emptyModal: false,
        modalCityName: '',
        ErrMsg,
        SuccessMsg,

        _nbOffresLogementsColl: null,
        _nbPlacesColl: null,
        _nbLogementsPrisColl: null,
        _nbPersonnesInstalleesColl: null,
        _nbreDePersEnCoursDeRelogementColl: null,
        _nbRefusFamillesColl: null,
        _nbRefusPersonneColl: null,
        _nbOffresDeLogementsEntr: null,
        _nbPlacesEntr: null,
        _nbreDePersEnCoursDeRelogementEntr: null,
        _nbRefusFamillesEntr: null,
        _nbRefusPersonneEntr: null,
        _nbOffreDeLogementsBail: null,
        _nbDePlacesBail: null,
        _nbLogementPrisBail: null,
        _nbPersonnesInstalleesBail: null,
        _nbreDePersEnCoursDeRelogementBail: null,
        _nbRefusFamillesBail: null,
        _nbRefusPersonneBail: null,
        _nbUkrainiensInstallesChrs: null,
        _dontXEnCoursDeRelogementChrs: null,
        _dontXRefusApsChrs: null,
        _nbRefusFamillesChrs: null,
        _nbRefusPersonneChrs: null,
        _nbOffresParticuliersPart: null,
        _nbDeLogementsIndependantsParticuliersPart: null,
        _nbPlacesLogementsIndependantsParticuliersPart: null,
        _nbUkrainiensInstallesChezDesParticuliersAvecImlPart: null,
        _nbUkrainiensDemandantAEtreRelogesPart: null,
        _visiteParLaFolPart: null,
        _nbRefusFamillesPart: null,
        _nbRefusPersonnePart: null,

        observations: []
      }
    },
    methods: {
        filterCity(dep_id){
            const filtered = this.cities.filter(city => {
                return city.id_arrondissement === dep_id
            })
            return filtered
        },
        getCityData(id, name){
            this.modalCityName = name
            const data = {
                ville_id: id
            }

            axios.post('/api/city-val-data', data, auth).then((result) => {
                if(result.data.length === 0) {
                    this.showModal = true
                    return this.emptyModal = true
                }
                this.showModal = true
                this._nbOffresLogementsColl= result.data[0].nb_offres_de_logements_coll,
                this._nbPlacesColl= result.data[0].nb_places_coll,
                this._nbLogementsPrisColl= result.data[0].nb_logements_pris_coll,
                this._nbPersonnesInstalleesColl= result.data[0].nb_personnes_installées_coll,
                this._nbreDePersEnCoursDeRelogementColl= result.data[0].nbre_de_pers_en_cours_de_relogemen_coll,
                this._nbRefusFamillesColl= result.data[0].nb_refus_famille_coll,
                this._nbRefusPersonneColl= result.data[0].nb_refus_personne_coll,

                this._nbOffresDeLogementsEntr= result.data[0].nb_offres_de_logements_entr,
                this._nbPlacesEntr= result.data[0].nb_places_entr,
                this._nbreDePersEnCoursDeRelogementEntr= result.data[0].nbre_de_pers_en_cours_de_relogemen_entr,
                this._nbRefusFamillesEntr= result.data[0].nb_refus_famille_entr,
                this._nbRefusPersonneEntr= result.data[0].nb_refus_personne_entr,

                this._nbOffreDeLogementsBail= result.data[0].nb_offres_de_logements_bail,
                this._nbDePlacesBail= result.data[0].nb_places_bail,
                this._nbLogementPrisBail= result.data[0].nb_logements_pris_bail,
                this._nbPersonnesInstalleesBail= result.data[0].nb_personnes_installées_bail,
                this._nbreDePersEnCoursDeRelogementBail= result.data[0].nbre_de_pers_en_cours_de_relogemen_bail,
                this._nbRefusFamillesBail= result.data[0].nb_refus_famille_bail,
                this._nbRefusPersonneBail= result.data[0].nb_refus_personne_bail,

                this._nbUkrainiensInstallesChrs= result.data[0].nb_ukrainiens_installés_chrs,
                this._dontXEnCoursDeRelogementChrs= result.data[0].dont_x_en_cours_de_relogemen_chrs,
                this._dontXRefusApsChrs= result.data[0].dont_x_refus_aps_chrs,
                this._nbRefusFamillesChrs= result.data[0].nb_refus_famille_chrs,
                this._nbRefusPersonneChrs= result.data[0].nb_refus_personne_chrs,

                this._nbOffresParticuliersPart= result.data[0].nb_offres_particuliers,
                this._nbDeLogementsIndependantsParticuliersPart= result.data[0].nb_de_logements_indépendants_particuliers,
                this._nbPlacesLogementsIndependantsParticuliersPart= result.data[0].nb_places_logements_indépendants_particuliers,
                this._nbUkrainiensInstallesChezDesParticuliersAvecImlPart= result.data[0].nb_ukrainiens_installés_chez_des_particuliers_avec_iml,
                this._nbUkrainiensDemandantAEtreRelogesPart= result.data[0].nb_ukrainiens_demandant_à_être_relogés,
                this._visiteParLaFolPart= result.data[0].visité_par_la_fol_part,
                this._nbRefusFamillesPart= result.data[0].nb_refus_famille_part,
                this._nbRefusPersonnePart= result.data[0].nb_refus_personne_part,
                this.observations = result.data[0].observations

            }).catch((err) => {
                return
            })
        },
    },
    async created(){
        const date = new Date()
        const format = date.toLocaleString('fr-FR', {timeZone: 'Europe/Paris'}).split(' ')[0]
        this.nowDate = format.replace(/\./g, '/')

        await axios.post('/api/get-all', {}, auth).then((res) => {
            this.data = res.data
        }).catch((err) => {
            console.log(err)
            return
        })

        await axios.post('/api/get-dep', {}, auth).then((res) => {
            this.departments = res.data.arrondissements
            axios.post('/api/get-cities', {}, auth).then((res) => {
                this.cities = res.data.cities
            }).catch((err) => {
                return
            })
        }).catch((err) => {
            return
        })
    }
})
</script>

<template>
    <div id="prefec-wrapper">
        <div id="defaultDiv-prefec">
            <p style="font-weight: bold;">{{nowDate}} semaine {{nowWeek}}-{{nowYear}}</p>

                <Teleport to="div">
                    <Transition>
                        <div id="modal-bg" v-if="showModal">
                            <div id="prefec-modal">
                                <button @click="showModal = false" class="btn-exit"><i class='bx bx-x'></i></button>
                                <h1>{{modalCityName}}</h1>
                                <h1 v-if="emptyModal">Rien a visualiser</h1>
                                <div v-if="!emptyModal">

                                    <h2>Logements collectivités</h2>
                                    <table>
                                        <tr>
                                            <th>Nb offres de logements</th>
                                            <th>Nb places</th>
                                            <th>Nb logements pris</th>
                                            <th>Nb personnes installées</th>
                                            <th>Nbre de pers en cours de relogement</th>
                                            <th>Nb refus familles</th>
                                            <th>Nb refus personne</th>
                                        </tr>
                                        <tr>
                                            <td>{{_nbOffresLogementsColl}}</td>
                                            <td>{{_nbPlacesColl}}</td>
                                            <td>{{_nbLogementsPrisColl}}</td>
                                            <td>{{_nbPersonnesInstalleesColl}}</td>
                                            <td>{{_nbreDePersEnCoursDeRelogementColl}}</td>
                                            <td>{{_nbRefusFamillesColl}}</td>
                                            <td>{{_nbRefusPersonneColl}}</td>
                                        </tr>
                                    </table>

                                    <h2>Logements entreprises-Association</h2>
                                    <table>
                                        <tr>
                                            <th>Nb offres de logements</th>
                                            <th>Nb places</th>
                                            <th>Nbre de pers en cours de relogement</th>
                                            <th>Nb refus familles</th>
                                            <th>Nb refus personne</th>
                                        </tr>
                                        <tr>
                                            <td>{{_nbOffresDeLogementsEntr}}</td>
                                            <td>{{_nbPlacesEntr}}</td>
                                            <td>{{_nbreDePersEnCoursDeRelogementEntr}}</td>
                                            <td>{{_nbRefusFamillesEntr}}</td>
                                            <td>{{_nbRefusPersonneEntr}}</td>
                                        </tr>
                                    </table>

                                    <h2>Logements bailleurs</h2>
                                    <table>
                                        <tr>
                                            <th>Nb offre de logements</th>
                                            <th>Nb de places</th>
                                            <th>Nb logement pris</th>
                                            <th>Nb personnes installées</th>
                                            <th>nbre de pers en cours de relogement</th>
                                            <th>Nb refus familles</th>
                                            <th>Nb refus personne</th>
                                        </tr>
                                        <tr>
                                            <td>{{_nbOffreDeLogementsBail}}</td>
                                            <td>{{_nbDePlacesBail}}</td>
                                            <td>{{_nbLogementPrisBail}}</td>
                                            <td>{{_nbPersonnesInstalleesBail}}</td>
                                            <td>{{_nbreDePersEnCoursDeRelogementBail}}</td>
                                            <td>{{_nbRefusFamillesBail}}</td>
                                            <td>{{_nbRefusPersonneBail}}</td>
                                        </tr>
                                    </table>

                                    <h2>Hébergements CHRS</h2>
                                    <table>
                                        <tr>
                                            <th>Nb Ukrainiens installés</th>
                                            <th>Dont x en cours de relogement</th>
                                            <th>Dont x refus APS</th>
                                            <th>Nb refus familles</th>
                                            <th>Nb refus personne</th>
                                        </tr>
                                        <tr>
                                            <td>{{_nbUkrainiensInstallesChrs}}</td>
                                            <td>{{_dontXEnCoursDeRelogementChrs}}</td>
                                            <td>{{_dontXRefusApsChrs}}</td>
                                            <td>{{_nbRefusFamillesChrs}}</td>
                                            <td>{{_nbRefusPersonneChrs}}</td>
                                        </tr>
                                    </table>

                                    <h2>Hébergements particuliers</h2>
                                    <table>
                                        <tr>
                                            <th>Nb offres particuliers</th>
                                            <th>Nb de logements indépendants particuliers</th>
                                            <th>Nb places logements indépendants particuliers</th>
                                            <th>Nb Ukrainiens installés chez des particuliers avec IML</th>
                                            <th>Nb Ukrainiens demandant à être relogés</th>
                                            <th>Visité par la FOL</th>
                                            <th>Nb refus familles</th>
                                            <th>Nb refus personne</th>
                                        </tr>
                                        <tr>
                                            <td>{{_nbOffresParticuliersPart}}</td>
                                            <td>{{_nbDeLogementsIndependantsParticuliersPart}}</td>
                                            <td>{{_nbPlacesLogementsIndependantsParticuliersPart}}</td>
                                            <td>{{_nbUkrainiensInstallesChezDesParticuliersAvecImlPart}}</td>
                                            <td>{{_nbUkrainiensDemandantAEtreRelogesPart}}</td>
                                            <td>{{_visiteParLaFolPart}}</td>
                                            <td>{{_nbRefusFamillesPart}}</td>
                                            <td>{{_nbRefusPersonnePart}}</td>
                                        </tr>
                                    </table>

                                    <h2>Observations</h2>
                                    <p class="obs-p" v-for="i in observations" :key="i">{{i}}</p>

                                </div>
                            </div>
                        </div>
                    </Transition>
                </Teleport>


            <div class="prefec-geo-wrapper"  v-for="(dep) in departments" :key="dep">
                <h3>{{dep.name}}</h3>
                <div class="prefec-geo-inner-wrapper">
                    <button class="city-btn" v-for="city in filterCity(dep.id)" :key="city.id" @click="getCityData(city.id, city.name)">{{city.name}}</button>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>
.btn-exit{
    margin: auto;
    background: none;
    outline: none;
    border: none;
    padding: 0.5em;
    margin: 0;
    color: #ff605c;
    font-size: 1.5em;
}
.btn-exit:hover{
    color: #ffffff;
}

#prefec-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #1b2631;
    background: #cbd7e6;
    font-family: Nunito;
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    height: 90%;
    width: 90%;
    overflow: auto;
    border-radius: 15px;
}
#modal-bg{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(10px);

}
#prefec-wrapper{
    color: #1b2631;
    border-radius: 10px;
    background: #0B6BA8;
    padding: 1em;
    margin: 1em;
}
#defaultDiv-prefec{
    font-weight: bold;
}
#defaultDiv-prefec h1, p{
    margin: 0;
}
.prefec-geo-inner-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
h3{
    color: #ffffff;
    margin-top: 2em;
    font-weight: bold;
}
h1{
    margin-top: 0;
}
.city-btn{
    font-size: 1em;
    height: 5em;
    border-radius: 5px;
    border: none;
    outline: none;
    margin: 0.2em;
    color: #dde5ee;
    font-weight: bold;
    background: #7593bd;

}
.city-btn:hover{
    background: #ffffff;
    color: #1b2631;
    transition: 0.1s;
}
.city-btn:active{
    background: #7593bd;
    color: #dde5ee;
    transition: 0.1s;
}
table{
    width: 100%;
    font-size: 0.8em;
}
table th, td {
  border:1px solid #324b67;
}
th{
    background: #324b67;
    color: #ffffff;
    border-radius: 5px;
    font-weight: 100;
}
td{
    background: #7597bd;
    color: black;
    border-radius: 5px;
}
h2{
    margin-bottom: 0;
    margin-inline: auto;
}
.obs-p{
    background: #324b67;
    color: #ffffff;
    font-weight: 100;
}
</style>