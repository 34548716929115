<script>
import { defineComponent } from 'vue'
import ErrMsg from '@/components/ErrMsg.vue'
import SuccessMsg from '@/components/SuccessMsg.vue'
const axios = require("axios")

export default defineComponent({
    name: 'AdminRegister',
    components: {
        ErrMsg,
        SuccessMsg,
    },
    data(){
      return{
        email: '',
        password: '',
        repeat_password: '',
        numeros: '',
        nom: '',
        prenom:'',
        active_err: false,
        active_succ: false,
        erro_msg: '',
        succ_msg: '',  
        } 
    },
    methods:{
        errorReset(){
            if(this.active_err){
                setTimeout(() => {
                    this.active_err = !this.active_err
                }, 3000)
            }
        },
        successReset(){
            if(this.active_succ){
                setTimeout(() => {
                    this.active_succ = !this.active_succ
                }, 3000)
            }
        },
        emailValidator(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email)
        },
        register(){
            if (this.email === '' || this.password === '' || this.repeat_password === '') {
                this.erro_msg = 'Remplir tous les champs obligatoires (*)'
                this.active_err = true
                return this.errorReset()
            }
            if(this.password !== this.repeat_password){
                this.erro_msg = 'Password ne correspond pas'
                this.active_err = true
                return this.errorReset()
            }
            if (!this.emailValidator(this.email)) {
                this.erro_msg = 'Email invalide'
                this.active_err = true
                return this.errorReset()
            }

            const data = {
                email: this.email,
                password: this.password
            }

            axios.post("/api/admin-add", data, {}).then((res) => {
                console.log(res.status);
                if(res.status === 200){
                    this.succ_msg = 'Compte créé avec succès, Redirection...'
                    this.active_succ = true
                    this.successReset()
                    setTimeout(() => {
                        return location.reload()
                    }, 3000)
                     
                }
            }).catch((err) => {
                this.erro_msg = `Une erreur s'est produite veuillez réessayer plus tard`
                this.active_err = true
                this.errorReset()
                setTimeout(() => {
                        return location.reload()
                }, 4000)
            })
        },

    }
})
</script>

<template>
    <div id="wrapper">
        <ErrMsg :active="active_err" :error_message="erro_msg"/>
        <SuccessMsg :active="active_succ" :success_message="succ_msg"/>
        <div id="form-wrapper">
            <h2>Registration admin</h2>
            <p>Tous les champs (*) sont obligatoires.</p>
            <label for="email">Email *</label>
            <input type="email" v-model="email" spellcheck="false">

            <label for="password">Mot de passe *</label>
            <input type="password" v-model="password" spellcheck="false">

            <label for="repeat_password">Repeter mot de passe *</label>
            <input type="password" v-model="repeat_password" spellcheck="false">

            <label for="numeros">Num. Tel.</label>
            <input type="tel" v-model="numeros" spellcheck="false">

            <label for="nom">Nom</label>
            <input type="text" v-model="nom" spellcheck="false">

            <label for="prenom">Prenom</label>
            <input type="text" v-model="prenom" spellcheck="false">
            <button @click="register">Registration<i class='bx bxs-lock bx-sm'></i></button>
        </div>
    </div>
</template>

<style scoped>
#wrapper{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #EFF2FF;
}
#form-wrapper{
    display: flex;
    padding: 1%;
    flex-direction: column;
    width: 30em;
    background: #ffffff;
    border-radius: 10px;
}
h2{
    color: #0B6BA8;
}
input{
    margin-bottom: none;
    margin-bottom: 1em;
    padding: 0.5em;
    font-size: 1.5em;
}
label{
    text-align: start;
    font-weight: bold;
}
button{
    margin: auto;
    width: 15em;
    padding: 0.5em;
    font-size: 1.2em;
    border-radius: 50px;
    background: #0B6BA8;
    border: none;
    color: #ffffff;
}
button:hover{
    background: #5277AB;
    transition: 0.1s;
}
button:active{
    background: #0B6BA8;
}
i{
    margin-left: 0.2em;
    vertical-align: middle;
}
</style>